import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import CardTeam from '../components/cardTeam'

const Leadership = ({ data, location }) => {
  const heroImage = data.heroImage
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
          />

    <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <CardTeam
                title={post.frontmatter.section[0].title}
                subtitle={post.frontmatter.section[0].subtitle}
                descrption=""
                image={post.frontmatter.section[0].image.childImageSharp.gatsbyImageData}
                alt={post.frontmatter.section[0].alttext}
                titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                LinkedInUrl={post.frontmatter.section[0].btnlink}
              />
              <MDBCol className="mt-5 mb-2 pl-4">
              <p className="font-w-700 title-medium">{post.frontmatter.section[0].title}</p>
                <p className="text-medium">{post.frontmatter.section[0].description}</p>
                <Link to="/about/corry-hong-founder-and-ceo-unicom-global/" className="effect">
                  Read more about Corry Hong
                </Link>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section ? (
          <section className="bg-white pt-0">
            <MDBContainer>
              <MDBRow>
                {post.frontmatter.section.map((sections, index) => (
                  <>
                    {index > 0 && (
                      <CardTeam
                        title={sections.title}
                        subtitle={sections.subtitle}
                        description={sections.description}
                        image={sections.image.childImageSharp.gatsbyImageData}
                        alt={sections.alttext}
                        titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                        LinkedInUrl={sections.btnlink}
                      />
                    )}
                  </>
                ))}
              </MDBRow>
            </MDBContainer>
          </section>
        ) : (
          ''
        )}
      </main>
    </Layout>
  )
}
export default Leadership

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, title: { eq: "Leadership" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        alttext
        image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
           }
        }
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 90)
            }
          }
          alttext
          btnlink
        }
      }
      html
    }
  }
`
